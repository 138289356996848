const copy = {
    heroBanner: {
        content: {
            title: "Thomas Léost",
            copy: [
              "Web Developer &",
              "Glorified Problem Solver"
            ]
        }
    },
    whoSection: {
        reverse: true,
        image: {
          src: "https://images.unsplash.com/photo-1618172193622-ae2d025f4032?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1859&q=80",
          alt: "Some squickly things"
        },
        content: {
          title: "Who am I?",
          copy: [
            'With a keen eye for analyzing the task at hand, sharpened by years of experience in experimenting and learning at best when things went wrong, broaden my knowledge in many different disciplines of Computer Science and parallel fields.',
            'This acquired knowledge has allowed me to break down problems and find successful solutions to the many tasks undertaken. Coupled with a sense of pride in my work, always making sure that the assigned work is complete to the specifications and time allocated whether working in a team or individually.'
          ]
        }
    },
    techSection: {
        image: {
          src: "https://images.unsplash.com/photo-1618172193622-ae2d025f4032?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1859&q=80",
          alt: "Some squickly things"
        },
        content: {
          title: "Tech Stack",
          copy: [
            'With a keen eye for analyzing the task at hand, sharpened by years of experience in experimenting and learning at best when things went wrong, broaden my knowledge in many different disciplines of Computer Science and parallel fields.',
            'This acquired knowledge has allowed me to break down problems and find successful solutions to the many tasks undertaken. Coupled with a sense of pride in my work, always making sure that the assigned work is complete to the specifications and time allocated whether working in a team or individually.'
          ]
        }
      }
}

export default copy;