import { useStaticQuery, graphql } from 'gatsby';

import { ListProjectsQuery } from '../API';

const useListProjects = () => {
    const data: ListProjectsQuery = useStaticQuery(graphql`
      query ListProjects{
        allContentfulProject {
          nodes {
            title
            link
            intro {
              intro
            }
            image {
              file {
                url
              }
              description
            }
          }
        }
      }
    `)
    return data;
  };

  export default useListProjects;