import React from 'react';
import styled from 'styled-components';

// Hooks
import useColour from '../../hooks/useColour';

// Components
import Card from '../Card';
import Button from '../Button';
import { Paragraph, SubHeading } from '../Typography';

import { Colour } from '../../types';

type ColouredCardProps = {
  colour: Colour,
  size: "sm" | "lg";
  image: Image,
  title: string
  copy: string
  button: Button
}

type Image = {
    src: string,
    alt: string
}
type Button = {
    copy: string,
    link: string
    newTab?: boolean
}

const ColouredCard: React.FC<ColouredCardProps> = (props) => {

  const { lightColour } = useColour(props.colour);


  return (
    <CardWrapper background={lightColour} size={props.size}>
      <Image {...props.image} />
      <CardSubHeading>{props.title}</CardSubHeading> 
      <Paragraph>{props.copy}</Paragraph> 
      <Button colour={props.colour} link={props.button.link} newTab={props.button.newTab} >{props.button.copy}</Button>
    </CardWrapper>
  );

}

const CardWrapper = styled(Card)<{background: string, size: "sm" | "lg"}>`
  background-color: ${({background}) => background};
  flex: calc(25% - 60px);
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${({theme}) => theme.breakpoints.lg}){
    flex: calc(50% - 60px);
  }
  @media screen and (max-width: ${({theme}) => theme.breakpoints.md}){
    flex: 100%;   
  }
`

const CardSubHeading = styled(SubHeading)`
  width: 100%
;`

const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 20px;
`


export default ColouredCard;