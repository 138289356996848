import React from 'react';
import styled from 'styled-components';

// Components
import ColouredCard from '../ColouredCard';
import { Heading } from '../Typography';

// Hooks
import useListProjects from '../../hooks/useListProjects';


type ProjectsProps = {}

const Projects: React.FC<ProjectsProps> = (props) => {
    
  const { allContentfulProject } = useListProjects();

  return <Wrapper id="projects">
    <Heading>Projects</Heading>
    <ProjectsWrapper>
      {
        allContentfulProject.nodes.map( (project, index) => {
          let colour = "Pink";
          switch(index){
            case 1: 
              colour = "Green";
              break;
            case 2: 
              colour = "Yellow";
              break;
            case 3: 
              colour = "Blue";
              break;
          }

          return <ColouredCard 
            title={project.title}
            copy={project.intro.intro}
            image={{src: project.image.file.url, alt: project.image.description}}
            size="sm"
            // @ts-expect-error
            colour={colour}
            button={{copy: "See Project", link: project.link, newTab: true }}
          />


        })
      }      
    </ProjectsWrapper>
  </Wrapper>;

}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
`

const ProjectsWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`

export default Projects;