import React from 'react';
import styled from 'styled-components';

// Components
import Card from '../Card';
import {Heading, Paragraph } from '../Typography';

type ContentCardProps = {
    id: string
    image: Image
    content: Content
    reverse?: boolean
}

type Image = {
    src: string
    "alt": string
}

type Content = {
    title: string
    copy: string[]
}

const ContentCard: React.FC<ContentCardProps> = (props) => {

    return (
        <CardWrapper id={props.id} reverse={props.reverse}>
            <Image {...props.image} reverse={props.reverse} />
            <ContentWrapper reverse={props.reverse}>
                <Heading>{props.content.title}</Heading>
                {props.content.copy.map( (copy) => <Paragraph>{copy}</Paragraph>)}
            </ContentWrapper>
        </CardWrapper>
    );
}

export default ContentCard;

const CardWrapper = styled(Card)<{reverse?: boolean}>`
    display: flex;
    flex-direction: ${ ({reverse}) => reverse? "row-reverse" : "row"};
    align-items: center;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.md}){
        flex-direction: column;
    }
`

const ContentWrapper = styled.div<{reverse?: boolean}>`
    width: 50%;
    padding: ${ ({reverse}) => reverse? "30px 30px 30px 100px" : "30px  100px 30px 30px"};
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-sizing: border-box;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.lg}){
        padding: ${ ({reverse}) => reverse? "20px 20px 20px 50px" : "20px  50px 20px 20px"};
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.md}){
        width: 100%;
        padding: 20px;
    }
`;

const Image = styled.img<{reverse?: boolean}>`
    border-radius: ${ ({reverse}) => reverse? "0 20px 20px 0" : "20px 0 0 20px"};
    width: 50%;
    height: 100%;
    object-fit: cover;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.md}){
        width: 100%;
        height: 300px;
        border-radius: 20px 20px 0 0;
    }
`;


