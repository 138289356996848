import React from 'react';
import styled from 'styled-components';

// Components
import Card from '../Card';
import {Title, Paragraph } from '../Typography';

// Assets
import ProfileImage from '../../assets/profile.png';

type HeroCardProps = {
    content: Content
}

type Content = {
    title: string
    copy: string
}

const HeroCard: React.FC<HeroCardProps> = (props) => {



    return (
        <CardWrapper>
            <ImageWrapper>
                <Image src={ProfileImage} alt="Styled profile" />
            </ImageWrapper>
            <ContentWrapper>
                <HeroTitle>{props.content.title}</HeroTitle>
                {props.content.copy.map( (copy) => <HeroText>{copy}</HeroText>)}
                
            </ContentWrapper>
        </CardWrapper>
    );
}

export default HeroCard;

const CardWrapper = styled(Card)`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.md}){
        flex-direction: column-reverse;
    }
`

const HeroTitle = styled(Title)`
    margin: 0;
`

const HeroText = styled(Paragraph)`
    color: ${({theme}) => theme.color.blue};
    margin: 0;
`

const ContentWrapper = styled.div`
    width: 30%;
    padding: 30px  100px 30px 30px;
    box-sizing: border-box;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.lg}){
        padding: 20px  50px 20px 20px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.md}){
        padding: 20px;
        width: 100%;
    }
`;

const Image = styled.img`
    width: 85%;
    margin-bottom: -5px;
`;

const ImageWrapper = styled.div`
    width: 60%;
    background-color: ${({theme}) => theme.color.lightBlue};
    border-radius: 20px 0 0 20px;
    text-align: center;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.md}){
        width: 100%;
        border-radius: 0 0 20px 20px;
    }
`


