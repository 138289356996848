import React from "react"
import styled, {ThemeProvider} from "styled-components";
import "../styles/anormalize.css";

// Components
import ContentCard from "../components/ContentCard"
import HeroCard from '../components/HeroCard'
import Projects from '../components/Projects';
import Nav from '../components/Nav';
import Seo from '../components/Seo';

// Constants
import Content from '../constants/content';
import Theme from '../constants/theme';
import Footer from "../components/Footer/Footer";

export default function Home() {

  return (
    <ThemeProvider theme={Theme}>
      <Seo title="Portfolio" />
      <PageWrapper>
        <Nav />
        <CardsWrapper>
          <HeroCard {...Content.heroBanner} />
          <ContentCard id="about" {...Content.whoSection} />
          <Projects />
          {/* <ContentCard  id="tech" {...Content.techSection} /> */}
          <Footer />
        </CardsWrapper>
      </PageWrapper>
    </ThemeProvider>
  )
}

const PageWrapper = styled.div`
  background-color: ${({theme}) => theme.color.background};
  display: flex;
  justify-content: center;
`

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1600px;
  padding: 150px 30px 40px;
  box-sizing: border-box;
  @media screen and (max-width: ${({theme}) => theme.breakpoints.sm}){
    padding: 150px 10px 40px;  
  }
`
