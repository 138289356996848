import React from 'react';
import styled from 'styled-components';

import useColour from '../../hooks/useColour';

import { Colour } from '../../types';

type ButtonProps = {
    link: string
    colour: Colour
    newTab?: boolean
}

const Button: React.FC<ButtonProps> = (props) => {

    const { lightColour, colour } = useColour(props.colour);

    const newTab = props.newTab ? { noopener: true, noreferrer: true, target:"_blank" } : undefined

    return <ButtonWrapper href={props.link} lightColour={lightColour} colour={colour} {...newTab}>
        {props.children}
    </ButtonWrapper>;

}

const ButtonWrapper = styled.a<{colour: string, lightColour: string}>`
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    color: ${ ({lightColour}) => lightColour};
    background-color: ${ ({colour}) => colour};
    font-family: futura-pt-bold, sans-serif;
    border: solid 3px;
    border-color: #ffffff;
    width: fit-content;
    padding 8px 16px;
    border-radius: 30px;
    text-decoration: none;
    &:hover{
        color: ${ ({colour}) => colour};
        background-color: ${ ({lightColour}) => lightColour};
    }
`

export default Button;